





































import ConclusionGraph from "@/components/charts/ConclusionGraph.vue";
import { Vue, Component } from "vue-property-decorator";
import draggable from "vuedraggable";
import MiniStat from "@/components/dashboard/MiniStat.vue";
import NProgress from "nprogress";
import { setPageTitle } from "@/utils/meta";

@Component({
  components: { MiniStat, draggable, ConclusionGraph },
})
export default class Dashboard extends Vue {
  widgets = {};
  saving = false;

  created() {
    setPageTitle("Dashboard");
    this.loadDashboard();
  }

  loadDashboard() {
    this.$api.get("dashboard").then(response => {
      this.widgets = response.data;
    });
  }

  saveDashboard() {
    NProgress.start();
    this.saving = true;
    this.$api.post("dashboard/save", this.widgets).then(() => {
      NProgress.done();
      this.saving = false;
    });
  }
}
